
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component
export default class Welcome extends Mixins(FormValidator) {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {});
      }
    }
  }
}
